<template>
  <section>
    <div class="block">
    </div>
    <b-field label="Roles" v-if="model[field.key]">
      <b-taginput
        v-model="model[field.key]"
        :data="roles"
        autocomplete
        :allow-new="false"
        :open-on-focus="true"
        field="name"
        placeholder="Add a role"
      ></b-taginput>
    </b-field>
  </section>
</template>
<script>

export default {
  name: 'roles',
  props: {
    baseModel: Object,
    field: Object,
    form: Object,
    model: Object,
    title: String,
    to: Object,
    value: String,
  },
  mounted() {
    this.dataService = this.$dataService(this.$route.params.tenant);
    this.dataService
      .getRoles()
      .then(({ data }) => {
        this.roles = data;
      })
      .catch(() => {
        this.$buefy.toast.open('Unable to retrieve roles list');
      });
  },
  data() {
    return {
      roles: null,
      type: null,
      label: this.$props.field.templateOptions.wrapper.properties.label,
    };
  },
  methods: {
    emitToParent() {},
  },
};
</script>
