<template>
  <b-field :label="label">
    <b-select
      :disabled="disabled"
      v-model="model[field.key]"
      @input="emitToParent"
      placeholder="Select a payment provider type">
      <option
        v-for="option in merchantTypes"
        :value="option.code"
        :key="option.code"
      >{{ option.name }}</option>
    </b-select>
  </b-field>
</template>
<script>

import CurrencyCodes from '@/formModels/generic/currencyCodes';
import CountryCodes from '@/formModels/generic/countryCodes';
import EventBus from '@/eventbus';
import baseFields from './baseFields';
import cardstreamFields from './cardstreamFields';
import wpmFields from './wpmFields';
import vyneFields from './vyneFields';
import westernUnionFields from './westernUnionFields';
import vynePartnerFields from './vynePartnerFields';

export default {
  name: 'merchantTypes',
  props: {
    baseModel: Object,
    field: Object,
    form: Object,
    model: Object,
    title: String,
    to: Object,
  },
  created() {
    this.dataService = this.$dataService(this.$route.params.tenant.toLowerCase());
    this.dataService
      .merchantTypes()
      .then(({ data }) => {
        this.merchantTypes = data;
      })
      .catch(() => {
        this.$buefy.toast.open('Unable to retrieve payment provider type list');
      });
  },
  computed: {
    disabled() {
      return (this.$route.params.merchantId);
    },
  },
  data() {
    return {
      merchantTypes: [],
      type: null,
      label: this.$props.field.templateOptions.wrapper.properties.label,
    };
  },
  methods: {
    emitToParent() {
      if (this.model.paymentProvider === 'CS') {
        EventBus.$emit('fields', baseFields.concat(cardstreamFields).concat(CountryCodes, CurrencyCodes), { countryCode: null, currencyCode: null });
      }
      if (this.model.paymentProvider === 'WPM') {
        EventBus.$emit('fields', baseFields.concat(wpmFields));
      }
      if (this.model.paymentProvider === 'VYNE') {
        EventBus.$emit('fields', baseFields.concat(vyneFields));
      }
      if (this.model.paymentProvider === 'WU') {
        EventBus.$emit('fields', baseFields.concat(westernUnionFields));
      }
      if (this.model.paymentProvider === 'VYNE_PARTNER') {
        EventBus.$emit('fields', baseFields.concat(vynePartnerFields));
      }
    },
  },
};
</script>
